/* stylelint-disable font-family-no-missing-generic-family-keyword */
html {
  font-family: var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif);
  font-weight: var(--tkww-union-typography-regular-font-weight, 400);
}
h1 {
  font-weight: var(--tkww-union-typography-category-h1-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h1-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h1-font-size, 1.875rem);
  line-height: var(--tkww-union-typography-category-h1-line-height, 1.2);
}

h2 {
  font-weight: var(--tkww-union-typography-category-h2-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h2-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h2-font-size, 1.75rem);
  line-height: var(--tkww-union-typography-category-h2-line-height, 1.2142857143);
}

h3 {
  letter-spacing: var(--tkww-union-typography-category-h3-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-h3-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h3-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h3-font-size, var(--tkww-union-typography-scale-600-font-size, 1.5rem));
  line-height: var(--tkww-union-typography-category-h3-line-height, 1.1666666667);
}

h4 {
  letter-spacing: var(--tkww-union-typography-category-h4-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-h4-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h4-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h4-font-size, 1.375rem);
  line-height: var(--tkww-union-typography-category-h4-line-height, 1.1818181818);
}

@media (max-width: 767.98px) {
  h1 {
    font-size: var(--tkww-union-typography-category-h1-font-size, 1.75rem);
    line-height: var(--tkww-union-typography-category-h1-line-height, 1.2142857143);
  }
}
@media (max-width: 767.98px) {
  h2 {
    font-size: var(--tkww-union-typography-category-h2-font-size, 1.625rem);
    line-height: var(--tkww-union-typography-category-h2-line-height, 1.2307692308);
  }
}
@media (max-width: 767.98px) {
  h3 {
    font-size: var(--tkww-union-typography-category-h3-font-size, 1.5rem);
    line-height: var(--tkww-union-typography-category-h3-line-height, 1.1666666667);
  }
}
@media (max-width: 767.98px) {
  h4 {
    font-size: var(--tkww-union-typography-category-h4-font-size, 1.375rem);
    line-height: var(--tkww-union-typography-category-h4-line-height, 1.1818181818);
  }
}
p {
  font-size: var(--tkww-union-typography-scale-300-font-size, 1rem);
  line-height: var(--tkww-union-typography-scale-300-line-height, 1.5);
}
strong {
  font-weight: 500;
}

em {
  font-style: italic;
}

a {
  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  font-family: var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif);
  font-weight: inherit;
}

p {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

html {
  /**
   * fontSize300 with hardcoded font-size
   */
  /* stylelint-disable unit-case */
  font-size: var(--tkww-union-typography-base-font-size, 16px);
  line-height: var(--tkww-union-typography-base-line-height, 1.5);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




a {
  color: var(--tkww-union-links-default-color, var(--tkww-union-color-link-on-light, var(--tkww-union-color-additional-01extra-400, #0073E6)));
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

:focus {
  outline: none;
}

:focus-visible {
  outline-color: var(--tkww-union-essentials-outline-color, var(--tkww-union-color-additional-01extra-400, #0073E6));
  outline-offset: var(--tkww-union-essentials-outline-offset, 2px);
  outline-style: solid;
  outline-width: 3px;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul {
  margin: 0;
  padding: 0;
}

img,
picture {
  display: block;
  height: auto;
  max-width: 100%;
  width: auto;
}

a,
area,
button,
[role=button],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

input,
textarea,
button,
select {
  color: inherit;
  font-family: inherit;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
:root {
  /* Font Sizes */
  --varFontSize100: var(--tkww-union-typography-scale-100-font-size, 0.75rem);
  --varFontSize200: var(--tkww-union-typography-scale-200-font-size, 0.875rem);
  --varFontSize300: var(--tkww-union-typography-scale-300-font-size, 1rem);
  --varFontSize400: var(--tkww-union-typography-scale-400-font-size, 1.125rem);
  --varFontSize500: var(--tkww-union-typography-scale-500-font-size, 1.25rem);
  --varFontSize600: var(--tkww-union-typography-scale-600-font-size, 1.5rem);
  --varFontSize700: var(--tkww-union-typography-scale-700-font-size, 1.75rem);
  --varFontSize800: var(--tkww-union-typography-scale-800-font-size, 2rem);
  --varFontSize900: var(--tkww-union-typography-scale-900-font-size, 2.25rem);
  --varCaptionLineHeight: ;
  /* Union Spacing */
  --varSpDown5: 0.0625rem;
  --varSpDown4: 0.125rem;
  --varSpDown3: 0.25rem;
  --varSpDown2: 0.5rem;
  --varSpDown1: 0.75rem;
  --varSpBase: 1rem;
  --varSpUp1: 1.25rem;
  --varSpUp2: 1.5rem;
  --varSpUp3: 1.75rem;
  --varSpUp4: 2rem;
  --varSpUp5: 2.25rem;
  --varSpUp6: 2.5rem;
  --varSpUp7: 2.75rem;
  --varSpUp8: 3rem;
  --varSpUp9: 3.25rem;
  --varSpUp10: 3.5rem;
  --varSpUp11: 3.75rem;
  --varSpUp12: 4rem;
  /* Union Colors */
  --varPrimary100: var(--tkww-union-color-primary-100, #FFE4FB);
  --varPrimary200: var(--tkww-union-color-primary-200, #FEBBF7);
  --varPrimary300: var(--tkww-union-color-primary-300, #FF44CB);
  --varPrimary400: var(--tkww-union-color-primary-400, #D700A0);
  --varPrimary500: var(--tkww-union-color-primary-500, #AC007C);

  --varNeutral100: var(--tkww-union-color-neutral-100, #F1F2F4);
  --varNeutral200: var(--tkww-union-color-neutral-200, #E4E5E9);
  --varNeutral300: var(--tkww-union-color-neutral-300, #CACCD0);
  --varNeutral400: var(--tkww-union-color-neutral-400, #9699A0);
  --varNeutral500: var(--tkww-union-color-neutral-500, #51545C);
  --varNeutral600: var(--tkww-union-color-neutral-600, #37393F);

  --varNeutralWhite: var(--tkww-union-color-neutral-white, #FFF);
  --varNeutralBlack: var(--tkww-union-color-neutral-black, #000);

  --varAdditional01Extra100: var(--tkww-union-color-additional-01extra-100, #E6F2FC);
  --varAdditional01Extra200: var(--tkww-union-color-additional-01extra-200, #C4DFF9);
  --varAdditional01Extra300: var(--tkww-union-color-additional-01extra-300, #A1CDF5);
  --varAdditional01Extra400: var(--tkww-union-color-additional-01extra-400, #0073E6);
  --varAdditional01Extra500: var(--tkww-union-color-additional-01extra-500, #364C9D);

  --varAdditional02Extra100: var(--tkww-union-color-additional-02extra-100, #FFF7E1);
  --varAdditional02Extra200: var(--tkww-union-color-additional-02extra-200, #FDDE81);
  --varAdditional02Extra300: var(--tkww-union-color-additional-02extra-300, #FBBB03);
  --varAdditional02Extra400: var(--tkww-union-color-additional-02extra-400, #FBAF00);
  --varAdditional02Extra500: var(--tkww-union-color-additional-02extra-500, #FB8B00);

  --varAdditional03Extra100: var(--tkww-union-color-additional-03extra-100, #FFD1BD);
  --varAdditional03Extra200: var(--tkww-union-color-additional-03extra-200, #FF9357);
  --varAdditional03Extra300: var(--tkww-union-color-additional-03extra-300, #FF6F00);
  --varAdditional03Extra400: var(--tkww-union-color-additional-03extra-400, #FA592D);
  --varAdditional03Extra500: var(--tkww-union-color-additional-03extra-500, #DB4936);

  --varAdditional04Extra100: var(--tkww-union-color-additional-04extra-100, #FFF5ED);
  --varAdditional04Extra200: var(--tkww-union-color-additional-04extra-200, #F7E6DC);
  --varAdditional04Extra300: var(--tkww-union-color-additional-04extra-300, #E8D5CA);
  --varAdditional04Extra400: var(--tkww-union-color-additional-04extra-400, #C7AFA1);
  --varAdditional04Extra500: var(--tkww-union-color-additional-04extra-500, #AD9587);

  --varStatusError100: var(--tkww-union-color-status-error-100, #FFECE4);
  --varStatusError200: var(--tkww-union-color-status-error-200, #EB1400);
  --varStatusError300: var(--tkww-union-color-status-error-300, #A1000B);

  --varStatusInfo100: var(--tkww-union-color-status-info-100, #F4F9FF);
  --varStatusInfo200: var(--tkww-union-color-status-info-200, #0073E6);
  --varStatusInfo300: var(--tkww-union-color-status-info-300, #114394);

  --varStatusSuccess100: var(--tkww-union-color-status-success-100, #E2FFEE);
  --varStatusSuccess200: var(--tkww-union-color-status-success-200, #008A05);
  --varStatusSuccess300: var(--tkww-union-color-status-success-300, #005A25);

  --varStatusWarning100: var(--tkww-union-color-status-warning-100, #FFF3DD);
  --varStatusWarning200: var(--tkww-union-color-status-warning-200, #C25400);
  --varStatusWarning300: var(--tkww-union-color-status-warning-300, #823300);

  --varBackgroundActive: var(--tkww-union-color-background-active, var(--tkww-union-color-additional-04extra-100, #FFF5ED));
  --varBackgroundDark: var(--tkww-union-color-background-dark, var(--tkww-union-color-neutral-black, #000));
  --varBackgroundDarkContrast: var(--tkww-union-color-background-dark-contrast, var(--tkww-union-color-neutral-400, #9699A0));
  --varBackgroundError: var(--tkww-union-color-background-error, var(--tkww-union-color-status-error-100, #FFECE4));
  --varBackgroundLight: var(--tkww-union-color-background-light, var(--tkww-union-color-neutral-white, #FFF));
  --varBackgroundLightContrast: var(--tkww-union-color-background-light-contrast, var(--tkww-union-color-neutral-100, #F1F2F4));
  --varBackgroundLightContrast2: var(--tkww-union-color-background-light-contrast-2, var(--tkww-union-color-neutral-200, #E4E5E9));
  --varBackgroundSuccess: var(--tkww-union-color-background-success, var(--tkww-union-color-status-success-100, #E2FFEE));
  --varBackgroundWarning: var(--tkww-union-color-background-warning, var(--tkww-union-color-status-warning-100, #FFF3DD));

  --varBrandPrimary: var(--tkww-union-color-brand-primary, var(--tkww-union-color-primary-300, #FF44CB));

  --varBorderDefault: var(--tkww-union-color-border-default, var(--tkww-union-color-neutral-300, #CACCD0));
  --varBorderError: var(--tkww-union-color-border-error, var(--tkww-union-color-status-error-200, #EB1400));
  --varBorderSelected: var(--tkww-union-color-border-selected, var(--tkww-union-color-neutral-500, #51545C));
  --varBorderSuccess: var(--tkww-union-color-border-success, var(--tkww-union-color-status-success-200, #008A05));

  --varCtaDefault: var(--tkww-union-color-cta-default, var(--tkww-union-color-primary-300, #FF44CB));
  --varCtaDisabled: var(--tkww-union-color-cta-disabled, var(--tkww-union-color-primary-200, #FEBBF7));
  --varCtaHover: var(--tkww-union-color-cta-hover, var(--tkww-union-color-primary-400, #D700A0));

  --varIconCta: var(--tkww-union-color-icon-cta, var(--tkww-union-color-primary-400, #D700A0));
  --varIconDefault: var(--tkww-union-color-icon-default, var(--tkww-union-color-neutral-black, #000));
  --varIconSubtle: var(--tkww-union-color-icon-subtle, var(--tkww-union-color-neutral-500, #51545C));

  --varLinkOnDark: var(--tkww-union-color-link-on-dark, var(--tkww-union-color-neutral-white, #FFF));
  --varLinkOnLight: var(--tkww-union-color-link-on-light, var(--tkww-union-color-additional-01extra-400, #0073E6));

  --varTextBrand: var(--tkww-union-color-text-brand, var(--tkww-union-color-primary-400, #D700A0));
  --varTextDefault: var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000));
  --varTextError: var(--tkww-union-color-text-error, var(--tkww-union-color-status-error-200, #EB1400));
  --varTextOnDarkSubtle: var(--tkww-union-color-text-on-dark-subtle, var(--tkww-union-color-neutral-300, #CACCD0));
  --varTextSubtle: var(--tkww-union-color-text-subtle, var(--tkww-union-color-neutral-500, #51545C));
  --varTextSuccess: var(--tkww-union-color-text-success, var(--tkww-union-color-status-success-200, #008A05));
  --varTextWarning: var(--tkww-union-color-text-warning, var(--tkww-union-color-status-warning-200, #C25400));

  /* progress bar meter color, used in onboarding */
  --varTeal900: #00746e;

  /* loading state middle gradient color */
  --varLoadingMiddleGradient: #fafbfc;

  /* This is the new shadow style that design wants us to use. */
  --varShadow: varShadow;

  /* Misc Variables */
  --carouselOffset: -18px;
  --contentMaxWidth: 1236px;
  --columnGutterWidth: 0.625rem;
}

.tile-border {
  border-radius: 8px;
}

body:has(.require-no-scroll) {
  overflow: hidden;
}

/*
  Prevents capturing of inner DOM nodes when wrapping
  groups of nodes in an anchor tag or an image so we
  can e.preventDefault() on an anchor, wait for analytics
  to fire, then send the user on their merry way
*/

[data-tracker] > * {
  pointer-events: none;
  cursor: pointer;
}

[data-tracker-pointer-events] > * {
  pointer-events: all;
  cursor: pointer;
}


